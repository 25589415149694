<template>
    <div class="global-view-card" v-loading="pageLoad">

        <div class="global-detail-head">订单详情</div>

        <div class="global-detail-content" style="padding-left: 10px">
            <el-card>
                <p class="content_title">基础信息</p>
                <div class="scyall-flex">
                    <div class="scyall-row" v-for="(item,i) in basicData" :key="i">
                        <div class="scyall-label">{{item.name}}</div>
                        <div class="scyall-value">
                            <div v-if="item.label == 'state'">
                                <span style="margin-right: 10px;">{{findState(item.data).label}}</span>

                                <el-button type="primary" size="mini" v-if="item.data == -1"
                                           @click="repayOrder(loanOrderNo)">重新打款
                                </el-button>
                                <el-button type="danger" size="mini" v-if="item.data == -1"
                                           @click="cancelOrder(loanOrderNo)">取消订单
                                </el-button>
                            </div>
                            <div v-else>{{item.data}}</div>

                        </div>
                    </div>
                </div>
                <el-table
                        :data="basicData"
                        border v-if="false">
                    <el-table-column
                            prop="loanOrderNo"
                            label="借款订单编号"
                            width="120"/>
                    <el-table-column
                            prop="disburseSerialNo"
                            label="用信流水号" width="100"/>
                    <el-table-column
                            prop="bankCardNo"
                            label="收款银行卡号" width="100"/>
                    <el-table-column
                            prop="amount"
                            label="借款本金" width="100"/>
                    <el-table-column
                            prop="remitAmount"
                            label="打款金额" width="142"/>
                    <el-table-column
                            prop="daysRate"
                            label="当前日息" width="142"/>
                    <el-table-column
                            prop="annualized"
                            label="当前年化利息"
                            width="142"/>

                    <el-table-column
                            prop="topAnnualized"
                            label="最高年化利息"
                            width="142"/>
                    <el-table-column
                            prop="periods"
                            label="借款总期数"
                            width="142"/>
                    <el-table-column
                            prop="interest"
                            label="借款利息"
                            width="142"/>
                    <el-table-column
                            prop="overdueRate"
                            label="逾期日利率"
                            width="142"/>
                    <el-table-column
                            prop="overduePunishRate"
                            label="逾期罚息利率"
                            width="142"/>
                    <el-table-column
                            prop="purposeDesc"
                            label="借款用途"
                            width="142"/>
                    <el-table-column
                            prop="borrowTime"
                            label="借款申请时间"
                            width="142"/>
                    <el-table-column
                            prop="realRemitTime"
                            label="实际打款时间"
                            width="142"/>
                    <el-table-column
                            prop="repaymentPlanTime"
                            label="借款计划结清时间"
                            width="142"/>
                    <el-table-column
                            prop="repaymentRealityTime"
                            label="借款实际结清时间"
                            width="142"/>

                    <el-table-column
                            prop="customerNo"
                            label="客户号" width="80"/>
                    <el-table-column
                            prop="userName"
                            label="姓名" width="80"/>
                </el-table>
            </el-card>
            <el-card style="margin-top: 10px;">
                <p class="content_title">打款记录</p>
                <el-table border :data="remitRecordVOList" size="mini">
                    <el-table-column prop="remitOrderNo" label="打款订单号" width="220"/>
                    <el-table-column prop="upsChannelCode" label="打款渠道" width="200"/>
                    <el-table-column prop="upsChannelOrderNo" label="支付通道流水号" width="260"/>
                    <el-table-column prop="amount" label="打款金额" width="100"/>
                    <el-table-column prop="remitStatus" label="支付状态" width="100">
                        <template slot-scope="{row}">
                            <el-tag type="primary" v-if="row.remitStatus == 0">打款中</el-tag>
                            <el-tag type="success" v-if="row.remitStatus == 1">打款成功</el-tag>
                            <el-tag type="danger" v-if="row.remitStatus == -1">打款失败</el-tag>
                        </template>
                    </el-table-column>
                    <el-table-column prop="failReason" label=" 支付失败原因" show-overflow-tooltip/>
                </el-table>
            </el-card>

            <el-card style="margin-top: 10px;">
                <p class="content_title">还款计划</p>
                <el-table border :data="repayPlanInfoVOList" size="mini">
                    <el-table-column prop="planOrderNo" label="还款计划订单号" width="260" fixed="left"/>
                    <el-table-column prop="currentPeriod" label="当前期数"/>
                    <el-table-column prop="planDueDate" label="还款日期"/>
                    <el-table-column prop="planDueTime" label="最后还款时间" width="145"/>
                    <el-table-column prop="periodAmount" label="本期应还本金" width="100"/>
                    <el-table-column prop="periodInterest" label="本期应还利息" width="100"/>
                    <el-table-column prop="repayOrderNo" label="还款单号" width="150"/>
                    <el-table-column prop="realRepaymentAmount" label="本期实际还款本金" width="120"/>
                    <el-table-column prop="realRepaymentInterest" label="本期实际还款利息" width="120"/>
                    <el-table-column prop="repaymentOverdueAmount" label="本期还款逾期费" width="120"/>
                    <el-table-column prop="realRepaymentTime" label="本期实际结清时间" width="145"/>
                    <el-table-column prop="isOverdue" label="是否逾期">
                        <template slot-scope="{row}">
                            <span v-if="row.isOverdue" style="color: red;font-weight: bolder;">是</span>
                            <span v-if="!row.isOverdue">否</span>
                        </template>
                    </el-table-column>
                    <el-table-column prop="overdueAmount" label="逾期金额"/>
                    <el-table-column prop="loanOverdueDay" label="逾期天数"/>
                    <el-table-column prop="periodState" label="本期还款状态" width="100" fixed="right">
                        <template slot-scope="{row}">
                            <el-tag type="primary" v-if="row.periodState == 0">待还款</el-tag>
                            <el-tag type="success" v-if="row.periodState == 1">已还款</el-tag>
                            <el-tag type="info" v-if="row.periodState == 2">还款中</el-tag>
                        </template>
                    </el-table-column>
                </el-table>
            </el-card>
            <el-card style="margin-top: 10px;">
                <p class="content_title">还款记录</p>
                <el-table border :data="orderRepayRecordVOList" size="mini">
                    <el-table-column prop="repayOrderNo" label="还款单号" width="140" fixed="left"/>
                    <el-table-column prop="upsChannelCode" label="支付通道标识" width="100"/>
                    <el-table-column prop="upsChannelOrderNo" label="支付通道流水号" width="120"/>
                    <el-table-column prop="repayTotalAmount" label="本次还款总金额" width="110"/>
                    <el-table-column prop="repayAmount" label="本次还款本金" width="100"/>
                    <el-table-column prop="repayOverdueAmount" label="本次还款还掉的逾期费" width="150"/>
                    <el-table-column prop="repayPoundage" label="本次还掉的服务费" width="120"/>
                    <el-table-column prop="repayInterest" label="本次还款还掉的利息" width="130"/>
                    <el-table-column prop="repayAccountNo" label="还款账户" width="130"/>
                    <el-table-column prop="failReason" label="还款失败原因" width="120" show-overflow-tooltip/>
                    <el-table-column prop="notifyTime" label="还款结果响应时间" width="145"/>
                    <el-table-column prop="repaySource" label="还款来源" width="100" fixed="right">
                        <template slot-scope="{row}">
                            <span v-if="row.repaySource == 0">用户主动还款</span>
                            <span v-if="row.repaySource == 1">系统自动扣款</span>
                            <span v-if="row.repaySource == 2">贷后减免</span>
                        </template>
                    </el-table-column>
                    <el-table-column prop="repayType" label="还款类型" width="100" fixed="right">
                        <template slot="header">
                            <span>还款类型</span>
                            <el-tooltip class="item" effect="dark" placement="top">
                                <div slot="content">说明：<br> 还款日正常还当期的为【正常还款】<br>非还款日提前还当期的为【当期结清】<br>
                                    逾期还款是按照当期全部金额做试算
                                </div>
                                <i class="el-icon-warning" style="color: #E6A23C;font-size: 14px;margin-left: 5px;"></i>
                            </el-tooltip>
                        </template>
                        <template slot-scope="{row}">
                            <span v-if="row.repayType == 1">全部结清</span>
                            <span v-if="row.repayType == 2">正常还款</span>
                            <span v-if="row.repayType == 3">当期结清</span>
                            <span v-if="row.repayType == 4">逾期还款</span>
                        </template>
                    </el-table-column>
                    <el-table-column prop="repayStatus" label="还款状态" width="100" fixed="right">
                        <template slot-scope="{row}">
                            <el-tag type="primary" v-if="row.repayStatus == 2">还款中</el-tag>
                            <el-tag type="success" v-if="row.repayStatus == 1">还款成功</el-tag>
                            <el-tag type="danger" v-if="row.repayStatus == 3">还款失败</el-tag>
                        </template>
                    </el-table-column>
                </el-table>
            </el-card>

        </div>
    </div>
</template>

<script>
    import {
        getOrderDetail, repayOrder, cancelOrder
    } from "@/api/business";

    export default {
        data() {
            return {
                pageLoad: false,
                loanOrderNo: this.$route.query.loanOrderNo || null,
                basicData: [],
                stateList: [{
                    label: '订单作废',
                    value: -2
                }, {
                    label: '打款失败',
                    value: -1
                }, {
                    label: '初始',
                    value: 0
                }, {
                    label: '签名中',
                    value: 1
                }, {
                    label: '等待打款',
                    value: 2
                }, {
                    label: '打款中',
                    value: 3
                }, {
                    label: '打款成功等待还款',
                    value: 4
                }, {
                    label: '结清',
                    value: 5
                }],
                remitRecordVOList: [],
                repayPlanInfoVOList: [],
                orderRepayRecordVOList: [],
                labelList: {
                    disburseSerialNo: '用信流水号',
                    loanOrderNo: '借款订单编号',
                    state: '借款状态',
                    bankCardNo: '用户收款的银行卡号',
                    amount: '借款本金(元)',
                    remitAmount: '打款金额(元)',
                    daysRate: '当前日息(%)',
                    annualized: '当前年化利息(%)',
                    topAnnualized: '最高年化利息(%)',
                    periods: '借款总期数(月)',
                    interest: '借款利息(元)',
                    overdueRate: '逾期日利率(%)',
                    overduePunishRate: '逾期罚息利率(%)',
                    purposeDesc: '借款用途',
                    borrowTime: '借款申请时间',
                    realRemitTime: '实际打款时间',
                    repaymentPlanTime: '借款计划结清时间',
                    repaymentRealityTime: '借款实际结清时间',
                    customerNo: '客户号',
                    userName: '姓名'
                }

            };
        },
        created() {
            // this.init();
            this.loanOrderNo && this.getOrderDetail()
        },
        methods: {
            findState(val) {
                return this.stateList.find(item => item.value == val)
            },
            getOrderDetail() {
                this.pageLoad = true;
                getOrderDetail({loanOrderNo: this.loanOrderNo})
                    .then((res) => {
                        if (res.data) {
                            const nameKey = Object.keys(this.labelList);
                            this.basicData = nameKey.map(item => ({
                                label: item,
                                name: this.labelList[item],
                                data: res.data[item],
                            }));
                            this.remitRecordVOList = res.data.remitRecordVOList || [];
                            this.repayPlanInfoVOList = res.data.repayPlanInfoVOList || [];
                            this.orderRepayRecordVOList = res.data.orderRepayRecordVOList || [];
                        }

                        this.pageLoad = false;
                    }).catch(() => {
                    this.pageLoad = false;
                });
            },

            repayOrder(loanOrderNo) {
                this.$messageBox
                    .confirm("是否确认重新打款？", "确认重新打款", {
                        confirmButtonText: "确认",
                        cancelButtonText: "取消",
                        type: "error",
                    })
                    .then(() => {
                        repayOrder({
                            loanOrderNo: loanOrderNo,
                        }).then(() => {
                            this.$message.success("重新打款成功");
                            this.getOrderDetail()
                        });
                    })
                    .catch(() => {
                        this.$message({
                            type: "info",
                            message: "已取消",
                        });
                    });
            },

            cancelOrder(loanOrderNo) {
                this.$messageBox
                    .confirm("是否确认取消该笔订单？", "确认取消", {
                        confirmButtonText: "确认",
                        cancelButtonText: "取消",
                        type: "error",
                    })
                    .then(() => {
                        cancelOrder({
                            loanOrderNo: loanOrderNo,
                        }).then(() => {
                            this.$message.success("取消成功");
                            this.getOrderDetail()
                        });
                    })
                    .catch(() => {
                        this.$message({
                            type: "info",
                            message: "已取消",
                        });
                    });
            },
        }
    };
</script>

<style lang="scss" scoped>
    .scyall-flex {
        width: 100%;
        border: 1px solid #EBEEF5;
        display: flex;
        flex-wrap: wrap;

        .scyall-row {
            width: 33.33%;
            display: flex;
            align-items: center;
            border-bottom: 1px solid #EBEEF5;

            .scyall-label {
                color: #909399;
                font-weight: bold;
                text-align: left;
                background: #f5f7fa;
                line-height: 23px;
                padding: 6px 0;
                padding-left: 10px;
                border-right: 1px solid #EBEEF5;
                width: 30%;

            }

            .scyall-value {
                width: 70%;
                padding-left: 10px;
            }
        }

        .scyall-row:nth-last-child(2){
            border-bottom: none;
        }

        .scyall-row:hover {
            background: #f5f7fa;
        }
    }
</style>

<style lang="scss">
    .banner-detail-dialog {
        .el-dialog__body {
            padding: 16px;
            .el-pagination {
                text-align: right;
                margin-top: 16px;
            }
        }
    }

    .content_title {
        font-size: 16px;
        font-weight: 600;
        color: #000000;
        margin-bottom: 10px;
    }

    .sub_title {
        font-size: 14px;
        font-weight: 600;
        color: #000000;
        margin: 10px 0;
    }

    .sub_header {
        display: flex;
        width: 100%;
        height: 80px;
        background: rgba(0, 0, 0, .3);
        display: flex;
        align-items: center;
        justify-content: space-between;

        .sub_status {
            display: flex;
            align-items: center;
            padding: 0 10px;

            .sub_label {
                font-size: 14px;
            }

            .sub_value {
                font-size: 18px;
                font-weight: 600;
                padding: 0 10px;
            }
        }

        .sub_operation {
            padding: 0 10px;
        }
    }

    .sub_content {
        display: flex;
        align-items: center;
        border: 1px solid #EBEEF5;
        background-color: #FFF;

        .sub_item {
            display: flex;
            width: 25%;
        }

        .sub_label {
            height: 100px;
            padding: 0 10px;
            font-weight: 600;
            font-size: 12px;
            color: #909399;
            background: #f5f7fa;
            line-height: 100px;
        }

        .sub_val {
            height: 100px;
            display: flex;
            align-items: center;
        }
    }


</style>
